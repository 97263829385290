.scan-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  .feedback-module {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
  }

  .scan-page-run {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .contact-support-container {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .primary-speed-test-button {
    font-family: ATTAleckSans_Bd;
  }

  .scan-page-run.scan-completed {
    @media all and (min-width: 1200px) {
      flex-direction: row;
      padding-bottom: 50px;
      .speed-test {
        padding-right: 5vw;
        align-items: flex-end;
      }

      .contact-support-container {
        align-items: flex-start;
        padding-left: 5vw;
        .contact-support {
          max-width: 465px;
        }
      }
    }
  }
}
