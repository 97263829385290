

@import './variables.scss';

// Styles
body {
  font-family: $primary-font;
  color: var(--primary-text-color);
  background-color: var(--theme-bg-color);
  margin: 0 auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  overflow-x: hidden;
}

.box-shadow {
  box-shadow: 0 2px 19px rgba(0, 0, 0, 0.205256);
}
.feedback-container {
  display: flex;
  justify-content: center;
}
.feedback-sizing {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 365px;
}

.att-background {
  background-color: #fff;
}

.att-dashboard {
  overflow: hidden;
}

.att-content-wrapper {
  position: relative;
  top: -100px;
}

.section-padding {
  padding: 20px;
}

.section-padding-x {
  padding: 0 20px;
}

.section-padding-y {
  padding: 20px 0;
}

.container {
  width: calc(100% - 16px);
}

.internet-services-holder {
  padding-bottom: 0;
}

.isp-holder {
  padding-top: 0;
}

.overview-data-card-button {
  padding-left: 0.8rem !important;
}

hr {
  height: 2px;
  margin: 50px -15px 0;
  background-color: $black;
}

// Media Queries
@media (min-width: 992px) {
  .internet-services-holder,
  .isp-holder {
    padding: 20px 0;
  }
}

@media (min-width: 1260px) {
  .container {
    max-width: 1260px;
  }
}

// Importing all the scss files at the END
@import './imports.scss';
