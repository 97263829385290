.browser-unsupported {
  background-color: #f0efef;
  margin-top: 20px;
  padding: 25px 15px 80px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .browser-unsupported-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 365px;

    .header {
      display: flex;
      font-size: 20px;
      text-align: center;
      justify-content: center;
      align-items: center;
      font-family: ATTAleckSans_Bd;
      margin-bottom: 20px;
      max-width: 365px;
      width: 100%;
    }

    .sub-header {
      display: flex;
      font-size: 14px;
      text-align: center;
      justify-content: center;
      align-items: center;
      font-family: ATTAleckSans_Bd;
      margin-bottom: 10px;
      max-width: 365px;
      width: 100%;
    }

    .section-start {
      margin-bottom: 20px;
    }

    .download-button {
      margin: 20px 0;
      text-align: center;
      display: flex;
      flex-direction: column;
      padding: 14px 30px;
      background: #0057b8;
      border-radius: 9999px;
      color: white;
      font-family: ATTAleckSans_Bd;
      cursor: pointer;
      font-size: 14px;
    }
  }
}
